import MainLayoutWrapper from "../components/layout/MainLayoutWrapper";
import OneColumnLeft from "../components/layout/OneColumnLeft";
import PriceTableColumn from "../components/sales/PriceTableColumn";
import { useDispatch, useSelector } from "react-redux";
import { updateUpgradeNow } from "../redux/formSlice";
import useReloadAccount from "../redux/useReloadAccount";
import { selectHeadline, selectIsAccountLoading, selectOfferDescription, selectRenewalAmount, selectSubPrice } from "../redux/accountSlice";
import { useMediaQuery } from "react-responsive";

function OfferPage() {
    const dispatch = useDispatch();
    const headline = useSelector(selectHeadline)
    const description = useSelector(selectOfferDescription)
    const renewalAmount = useSelector(selectRenewalAmount)
    const subPrice = useSelector(selectSubPrice)
    const isAccountLoading = useSelector(selectIsAccountLoading)
    const isMobile = useMediaQuery({ maxWidth: 600 });
    useReloadAccount('/account-search', true, 'Account not found. Please try again. Make sure the number you are using is a 6 digit number with no spaces.');

    const columns = [
        (<PriceTableColumn
            programName="Current Termite Only Program"
            price={renewalAmount + " / year"}
            priceDescription="First Year Paid for By Builder"
            includes={[
                "Preventative termite treatment (during construction)",
                "Termite Warranty"
            ]}
            doesNotInclude={[
                "Quarterly preventative pest control (ants, spiders, millipedes)",
                "Rodent control for mice & rats",
                "Bee/wasp removal",
                "Free callbacks if you have an issue between service"
            ]}
        />),
        (<PriceTableColumn
            programName="SeasonGuard"
            price={"39/month"}
            priceDescription="Setup Fee Waived (Usually $150)"
            headerColor="#C2B51E"
            subeaderColor="#FFF46F"
            includes={[
                "Preventative termite treatment (during construction)",
                "Termite Warranty",
                "Quarterly preventative pest control (ants, spiders, millipedes)",
                "Rodent control for mice & rats",
                "Bee/wasp removal",
                "Free callbacks if you have an issue between service"
            ]}
        />)
    ]


    return (
        <MainLayoutWrapper
            headline="Termite Program Registration"
            lastUrl="/delay"
            nextUrl="/complete-yes"
            lastUrlText="Decline Offer"
            nextUrlText={isMobile ? "Upgrade Now" : "Upgrade Now"}
            handleNext={() => dispatch(updateUpgradeNow(true))}
            handleLast={() => dispatch(updateUpgradeNow(false))}
            isLoading={isAccountLoading}
            isPrevDeemphasized={true}
        >
            <OneColumnLeft
                callout="LIMITED TIME OFFER!"
                title={headline}
                mobileTitle={headline}
                mobileDescription={<span>{description} <a className="text-yellowSecondary underline hover:opacity-80 font-bold" href="https://bettertermite.ac-page.com/season-guard-new-construction" target="_blank">More Information</a></span> }
                description={<span>{description} <a className="text-yellowSecondary underline hover:opacity-80 font-bold" href="https://bettertermite.ac-page.com/season-guard-new-construction" target="_blank">More Information</a></span> }
                padding="pt-24 mb-36"
                fullHeight=""
            >
                <div className="hidden md:flex justify-center gap-x-4">
                    {columns}
                </div>
                <div className="md:hidden flex flex-col gap-y-4 items-center">
                    {[...columns].reverse()} 
                </div>
            </OneColumnLeft>
        </MainLayoutWrapper>
    )
}

export default OfferPage